define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-wizard-show", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", "discourse/lib/ajax", "discourse/routes/discourse", "I18n", "@ember/service"], function (_exports, _customWizardAdmin, _ajax, _discourse, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    model(params) {
      if (params.wizardId === "create") {
        return {
          create: true
        };
      } else {
        return (0, _ajax.ajax)(`/admin/wizards/wizard/${params.wizardId}`);
      }
    },
    afterModel(model) {
      if (model.none) {
        return this.router.transitionTo("adminWizardsWizard");
      }
    },
    setupController(controller, model) {
      const parentModel = this.modelFor("adminWizardsWizard");
      const wizard = _customWizardAdmin.default.create(!model || model.create ? {} : model);
      const fieldTypes = Object.keys(parentModel.field_types).map(type => {
        return {
          id: type,
          name: _I18n.default.t(`admin.wizard.field.type.${type}`)
        };
      });
      let props = {
        wizardList: parentModel.wizard_list,
        fieldTypes,
        userFields: parentModel.userFields,
        customFields: parentModel.custom_fields,
        apis: parentModel.apis,
        themes: parentModel.themes,
        wizard,
        currentStep: wizard.steps[0],
        currentAction: wizard.actions[0],
        creating: model.create
      };
      controller.setProperties(props);
      controller.setAfterTimeGroupIds();
    }
  });
});
define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-composer-editor", ["exports", "discourse/components/composer-editor", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/lib/uploads", "@ember/string", "discourse/components/modal/insert-hyperlink", "@ember/service", "@ember/object"], function (_exports, _composerEditor, _decorators, _computed, _uploads, _string, _insertHyperlink, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wizardComposerEdtiorEventPrefix = _exports.default = void 0;
  const wizardComposerEdtiorEventPrefix = _exports.wizardComposerEdtiorEventPrefix = "wizard-editor";
  class CustomWizardComposerEditor extends _composerEditor.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    allowUpload = true;
    showLink = false;
    topic = null;
    showToolbar = true;
    focusTarget = "reply";
    canWhisper = false;
    lastValidatedAt = "lastValidatedAt";
    popupMenuOptions = [];
    draftStatus = "null";
    static #_2 = (() => dt7948.g(this.prototype, "loadingMore", [(0, _computed.alias)("topicList.loadingMore")]))();
    #loadingMore = (() => (dt7948.i(this, "loadingMore"), void 0))();
    wizardEventFieldId = null;
    composerEventPrefix = (() => wizardComposerEdtiorEventPrefix)();
    init() {
      super.init(...arguments);
      this.fileUploadElementId = `file-uploader-${(0, _string.dasherize)(this.field.id)}`;
      this.editorInputClass = `.${(0, _string.dasherize)(this.field.type)}-${(0, _string.dasherize)(this.field.id)} .d-editor-input`;
    }
    allowedFileTypes() {
      return this.siteSettings.authorized_extensions.split("|").map(ext => "." + ext).join(",");
    }
    static #_3 = (() => dt7948.n(this.prototype, "allowedFileTypes", [_decorators.default]))();
    uploadIcon() {
      return (0, _uploads.uploadIcon)(false, this.siteSettings);
    }
    static #_4 = (() => dt7948.n(this.prototype, "uploadIcon", [(0, _decorators.default)()]))();
    _handleImageDeleteButtonClick() {
      this.session.set("wizardEventFieldId", this.field.id);
      super._handleImageDeleteButtonClick(...arguments);
    }
    static #_5 = (() => dt7948.n(this.prototype, "_handleImageDeleteButtonClick", [_decorators.bind]))();
    extraButtons(toolbar) {
      const component = this;
      if (this.allowUpload && this.uploadIcon) {
        toolbar.addButton({
          id: "upload",
          group: "insertions",
          icon: this.uploadIcon,
          title: "upload",
          sendAction: event => component.send("showUploadModal", event)
        });
      }
      toolbar.addButton({
        id: "link",
        icon: "link",
        group: "insertions",
        shortcut: "K",
        trimLeading: true,
        unshift: true,
        sendAction: event => component.send("showLinkModal", event)
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "extraButtons", [_object.action]))();
    showLinkModal(toolbarEvent) {
      let linkText = "";
      this._lastSel = toolbarEvent.selected;
      if (this._lastSel) {
        linkText = this._lastSel.value;
      }
      this.modal.show(_insertHyperlink.default, {
        model: {
          linkText,
          toolbarEvent
        }
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "showLinkModal", [_object.action]))();
    showUploadModal() {
      this.session.set("wizardEventFieldId", this.field.id);
      document.getElementById(this.fileUploadElementId).click();
    }
    static #_8 = (() => dt7948.n(this.prototype, "showUploadModal", [_object.action]))();
    _uploadDropTargetOptions() {
      return {
        target: this.element,
        onDrop: () => {
          this.session.set("wizardEventFieldId", this.field.id);
        }
      };
    }
  }
  _exports.default = CustomWizardComposerEditor;
});